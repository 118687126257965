<template>
  <div class="sub_container">
    <div class="sub_wrapper">
        <h2 class="sub-title1">{{ $t('menu5-detail') }}</h2>
        <el-row>
           <el-col :sm="24">
            <el-card class="box-card">
              <div class="d-flex align-items-center">
                <h4 class="stit01">{{ $t('columnAddress') }}</h4>
                <p class="d-flex align-items-center">
                  <span class="subtxt01 ml10 block_hash_txt">{{accountId}}</span>&nbsp;
                  <span class="ico_copy"
                    @click="$func.copy_to_clipboard(accountId);
                    $message({message: $t('msgCopy'), type: 'success' });">
                    copy
                  </span>
                </p>
              </div>
              <el-divider></el-divider>
              <el-row>
                <el-col :sm="10">
                  <SPieChart :statsData="chartOptions"></SPieChart>
                </el-col>
                <el-col :sm="14" class="account_chart_data">
                  <h4 class="account_total mt20 mb20">
                    {{ $t('txTotalGURUCoin') }}
                    <span class="subtxt01 total ml10">
                      <span clas="value"
                      v-html="$options.filters.currency(accountData.total)">
                      </span>
                    </span>
                    <span class="txt_guru">{{accountData.symbol}}</span>
                  </h4>
                  <dl class="dl_header">
                    <dt>{{ $t('txCategory') }}</dt>
                    <dt>{{ $t('columnAmount') }}</dt>
                    <dt>{{ $t('txPercent') }}</dt>
                  </dl>
                  <dl>
                    <dt><span class="legend_Available"></span>{{ $t('txBalance') }}</dt>
                    <dd>
                      <span v-html="$options.filters.currency(accountData.balance)"></span>
                    </dd>
                    <dd>{{accountData.balancePercent}}%</dd>
                  </dl>
                  <dl>
                    <dt><span class="legend_Delegated"></span>{{ $t('txDelegated') }}</dt>
                    <dd>
                      <span v-html="$options.filters.currency(accountData.tot_bonded)"></span>
                    </dd>
                    <dd>{{accountData.totBondedPercent}}%</dd>
                  </dl>
                  <dl>
                    <dt><span class="legend_Unbonding"></span>{{ $t('txUnbonding') }}</dt>
                    <dd>
                      <span v-html="$options.filters.currency(accountData.tot_unbonded)"></span>
                    </dd>
                    <dd>{{accountData.totUnbondedPercent}}%</dd>
                  </dl>
                  <dl>
                    <dt><span class="legend_Reward"></span>{{ $t('txReward') }}</dt>
                    <dd>
                      <span v-html="$options.filters.currency(accountData.tot_rewards)"></span>
                    </dd>
                    <dd>{{accountData.totRewardsPercent}}%</dd>
                  </dl>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :sm="24" :md=24 :lg=24>
            <h2 class="sub-title2">
               {{ $t('menu3-1') }}
             </h2>
            <!-- List //-->
            <STtable  :columnes ="txsColumnes"
                      :tableData ="txsData"
                      :total_data = "txTotal"
                      :loading="loading"
                      @handleCurrentChange="handleCurrentChange">
            </STtable>
          </el-col>
          <el-col :sm="24" :md=12 :lg=12 class="pr10">
           <h2 class="sub-title2">
                {{ $t('txtDelegationList') }}
            </h2>
            <Stable :columnes="Columnes"
                    :tableData="delegationData"
                    :total_data ="total_delegation"
                    :loading="loading"
                    :pageActive="deleActive">
            </Stable>
          </el-col>
          <el-col :sm="24" :md=12 :lg=12 class="pl10">
            <h2 class="sub-title2">
                {{ $t('txtUnbondingList') }}
            </h2>
            <Stable :columnes="Columnes"
                    :tableData="unbondingsData"
                    :total_data ="total_unbonding"
                    :loading="loading"
                    :pageActive="unbonActive">
            </Stable>
          </el-col>
        </el-row>
      </div>
    </div>
</template>
<script>
import STtable from '@/components/STtable.vue';
import Stable from '@/components/Stable.vue';
import SPieChart from '@/components/SPieChart.vue';

export default {
  name: 'accountDetail',
  components: {
    STtable,
    Stable,
    SPieChart,
  },
  computed: {
    deleActive() {
      if (this.total_delegation > 0) return true;
      return false;
    },
    unbonActive() {
      if (this.total_unbonding > 0) return true;
      return false;
    },
  },
  data() {
    return {
      accountData: {},
      loading: false,
      txsColumnes: [{
        label: 'columnResult', prop: 'tx_status', width: '100', class: '', router: '', key: '',
      },
      {
        label: 'columnHeight', prop: 'block_nm', width: '150', class: '', router: 'blocksDetail', key: 'block_nm',
      },
      {
        label: 'columnTxHash', prop: 'tx_id', width: '250', class: '', router: 'transactionDetail', key: 'tx_id',
      },
      {
        label: 'columnTxType', prop: 'tx_type', width: '150', class: '', router: '', key: '',
      },
      {
        label: 'columnAmount', prop: 'amount', width: '200', class: '', router: '', key: '',
      },
      {
        label: 'columnTime', prop: 'time_ago', width: '300', class: '', router: '', key: '',
      },
      ],
      Columnes: [{ label: 'columnValidatorAddress', prop: 'validator_address', width: '72%' },
        { label: 'columnAmount', prop: 'token', width: '150' },
      ],
      txTotal: 0,
      total: 1,
      txsData: [],
      delegationData: [],
      total_delegation: 0,
      unbondingsData: [],
      total_unbonding: 0,
      accountId: '',
      chartOptions: {
        chart: {
          type: 'pie',
          options3d: {
            enabled: true,
            alpha: 45,
          },
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
        },
        title: { text: '' },
        subtitle: { text: '' },
        plotOptions: {
          pie: {
            innerSize: 100,
            depth: 80,
          },
        },
        credits: { enabled: false },
        series: [],
        colors: ['#6c60ff', '#fc6060', '#feda60', '#6ec53b'],
      },
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.loading = true;
      this.getTxsData(val);
    },
    async getAccount() {
      try {
        const { data } = await this.$axios.get(`/account/${this.accountId}`);
        if (data.ret_code === 200) {
          this.accountData = data.data;
          this.delegationData = data.data.delegations;
          this.total_delegation = data.data.delegations.length;
          this.unbondingsData = data.data.unbondings;
          this.total_unbonding = data.data.unbondings.length;
          const { total } = data.data;
          const balance = this.isNumber((data.data.balance / total) * 100);
          const bonded = this.isNumber((data.data.tot_bonded / total) * 100);
          const unbonded = this.isNumber((data.data.tot_unbonded / total) * 100);
          const rewards = this.isNumber((data.data.tot_rewards / total) * 100);
          this.accountData.balancePercent = this.isNumber(balance.toFixed(2));
          this.accountData.totBondedPercent = this.isNumber(bonded.toFixed(2));
          this.accountData.totUnbondedPercent = this.isNumber(unbonded.toFixed(2));
          this.accountData.totRewardsPercent = this.isNumber(rewards.toFixed(2));
          this.chartOptions.series = [{
            name: 'Delivered amount',
            data: [
              ['Balance', balance],
              ['Delegated', bonded],
              ['Unbonding', unbonded],
              ['Reward', rewards],
            ],
          }];
        } else {
          this.$message.error(`${this.accountId}: ${this.$t('411codeMessage')}`);
          this.$router.push({ name: 'home' });
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    isNumber(val) {
      if (Number.isNaN(Number(val))) return 0;
      return val;
    },
    getTxsData(page) {
      this.$axios.get(`/account/${this.accountId}/txs?page_no=${page}&list_size=10`)
        .then((res) => {
          if (res.data.ret_code === 200) {
            this.txTotal = res.data.data.tx_tot;
            this.txsData = res.data.data.txs;
          }
        })
        .catch((error) => { this.$message.error(error); })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.accountId = this.$route.params.me;
    this.getAccount();
    this.getTxsData(1);
  },
  mounted() {},
};
</script>
