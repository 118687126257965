<template>
    <div>
        <el-card class="box-card">
          <div class="el-table el-table--fit el-table--striped
                      el-table--enable-row-hover
                      el-table--enable-row-transition"
                style="width: 100%; margin-bottom: 15px;">
            <div class="el-table__header-wrapper">
              <table cellspacing="0" cellpadding="0" border="0"
                    class="el-table__header">
                <thead class="has-gutter">
                  <tr class="">
                    <th  v-for="(col, index) in columnes"
                      :key="index"
                      :width="col.width"
                      class="el-table__cell">
                    <div class="cell" >{{$t(col.label)}}</div></th>
                  </tr></thead>
              </table></div>
            <div class="el-table__body-wrapper is-scrolling-none">
              <table cellspacing="0" cellpadding="0" border="0"
                    class="el-table__body">

                <tbody  name="list" is="transition-group" >
                <tr v-for="(data, idx) in tableData"
                    v-bind:key="data[columnes[0].prop]"
                    :class="(idx%2 ==0)?'el-table__row' :'el-table__row el-table__row--striped'" >
                  <td v-for="(col, index) in columnes"
                     :key="index"
                     :width="col.width"
                     :class="(col.router !='')?'el-table__cell tdlink ':'el-table__cell '"
                     @click="routerGo(col.router, data[col.key] )">
                    <div class="cell pd00" v-if="col.prop == 'block_hash'">
                      {{data[col.prop] | readMore}}</div>
                    <div class="cell pd00" v-else-if="col.prop == 'delegator_address'">
                      {{data[col.prop] | readMore}}</div>
                    <div :class="`cell fl_right ${col.class}`" v-else-if="col.prop == 'tokens'">
                        <span v-html="$options.filters.currency(data[col.prop])"></span>
                        {{data['symbol']}}
                    </div>
                    <div :class="`cell fl_right ${col.class}`" v-else-if="col.prop == 'token'">
                        <span v-html="$options.filters.currency(data[col.prop])"></span>
                        {{data['symbol']}}
                    </div>
                    <div class="cell fl_right mr05" v-else-if="col.prop == 'time_ago'">
                      {{ data['reg_dt'] }} UTC
                      ({{data[col.prop]}})</div>
                    <div :class="`cell ${col.class}`" v-else>{{data[col.prop]}}</div>
                  </td>
                  </tr>
                </tbody>
                </table>
                <span v-if="total_data == 0" class="no_data">{{$t('txNoData')}}</span>
            </div>
          </div>
          <div class="sub_table el-table-m">
            <transition-group >
              <dl class="display_grid"
                v-for="(data) in tableData"
                v-bind:key="data[columnes[0].prop]"
              >
                <template  v-for="(col, index) in columnes">
                          <dt :key="index" >{{$t(col.label)}}</dt>
                          <dd :key="col.prop" :class="(col.router !='')? 'tdlink': '' +col.class"
                               v-if="col.prop == 'block_hash'"
                               @click="routerGo(col.router, data[col.key] )">
                               {{data[col.prop] | readMore}}</dd>
                          <dd :key="col.prop" :class="col.class"
                              v-else-if="col.prop == 'time_ago'">
                              {{data['reg_dt']}} UTC
                              ({{data[col.prop]}})</dd>
                          <dd :key="col.prop"
                              v-else-if="col.prop == 'tokens'">
                              <span v-html="$options.filters.currency(data[col.prop])"> </span>
                              {{data['symbol']}}</dd>
                           <dd :key="col.prop"
                              v-else-if="col.prop == 'token'">
                              <span v-html="$options.filters.currency(data[col.prop])"></span>
                              {{data['symbol']}}</dd>
                          <dd :key="col.prop"  :class="(col.router !='')? 'tdlink': '' +col.class"
                              @click="routerGo(col.router, data[col.key] )"
                              v-else>{{data[col.prop]}}</dd>
                </template>
              </dl>
            </transition-group>
            <span v-if="total_data == 0" class="no_data">{{$t('txNoData')}}</span>
          </div>
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="10"
            :total="total_data"
            :pager-count="5"
            @current-change="handleCurrentChange" v-if="pageActive == true">
          </el-pagination>
      </el-card>
    </div>
</template>

<script>
export default {
  name: 'Stable',
  props: {
    columnes: Array,
    tableData: Array,
    loading: Boolean,
    total_data: Number,
    pageActive: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
    };
  },
  computed: {
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.$emit('handleCurrentChange', val);
    },
    routerGo(routerName, id) {
      if (routerName === '') return;
      this.$router.push({
        name: routerName, params: { me: id },
      });
    },
  },
};
</script>
<style>
.list-enter-active {
  transition: all 2s;
}
.list-leave-active{
transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
 }
.list-enter, .list-leave-to{
  opacity: 0;
  transform: translateX(30px);
}
.list-move {
  transition: transform 2s;
}
</style>
