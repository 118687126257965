<template>
    <div>
        <highcharts :options="statsData"></highcharts>
    </div>
</template>

<script>
export default {
  name: 'PieChart',
  props: {
    statsData: Object,
  },
};
</script>
